import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import VisualNavigation from "Layout/Navigation/VisualNavigation/VisualNavigation"

const Techniques = ({ location, data }) => {
  const {page, optionsPage, menu} = data
  const techniquesMenu = menu.edges[0]
  const gI = optionsPage.edges[0].node.global_data.generic_images

  return (
    <MainLayout location={location}>
      <div className="section-techniques-content section pt-2 pt-md-5 mb-7 container-fluid">
        <div className="container">
          <div className="row techniques-details-row justify-content-end">
            <div className="techniques-content col-12 col-lg-7 order-1 order-lg-2">
              <h1 className="mb-1">{page.title}</h1>
              {
                page.acf.intro_text.paragraphs && page.acf.intro_text.paragraphs.map(text => (
                  <div
                    key={text.paragraph}
                    dangerouslySetInnerHTML={{ __html: text.paragraph }}
                    className="techniques-description-container mb-1"
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <VisualNavigation items={techniquesMenu} genericImages={gI} />
    </MainLayout>
  )
}

export const query = graphql`
  query techniquesPageQuery {
    page: wordpressPage(wordpress_id: {eq: 861}) {
      title
      id
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    menu: allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Techniques Menu"}}) {
      edges {
        node {
          name
          items {
            title
            object_slug
            wordpress_id
            url
            target
          }
        }
      }
    }
  }
`

export default Techniques
